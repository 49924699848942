.spotlight-outer-container {
  position: relative;
  padding: 40px;
  height: 100vh;
  max-width: 1400px;
  margin: auto;
}

.spotlight-container {
  overflow: hidden;
  position: relative;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  height: 700px;
  color: white;
  border-radius: 5px;

  &__title {
    text-transform: uppercase;
    color: yellow !important;
    font-weight: bold !important;
    letter-spacing: 0.8rem;
    font-size: 1.8rem !important;
    background-color: rgb(79, 79, 242);
    padding: 9px;
    border-radius: 10px;
    text-align: center;
  }

  &__line {
    height: 2px;
    background-color: yellow;
    width: 20%;
    margin: 20px 0;
  }

  &__name {
    color: yellow;
    text-transform: uppercase;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    font-size: 1.2rem !important;
  }

  &__instrument {
    font-style: italic;
    font-size: 1.2rem !important;
  }

  &__details {
    font-style: italic;
    margin: 60px 0 !important;
    padding-left: 10px;
  }
  &__image {
    position: absolute;
    right: -90px;
    width: 60%;
    height: 105%;
    & img {
      object-fit: cover;
      height: 100%;
      min-width: 100%;
    }
  }

  &__text-container {
    position: relative;
    width: 30%;

    padding: 50px;
    z-index: 700;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -260px;
      width: 300px;
      height: 100%;
      background: #000;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: skew(-10deg);
      -ms-transform: skew(-10deg);
      transform: skew(-10deg);
      z-index: -1;
    }
  }
}

@media (max-width: 1200px) {
  .spotlight-container {
    overflow: hidden;
    position: relative;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    color: white;

    &__title {
      text-transform: uppercase;
      color: yellow !important;
      font-weight: bold !important;
      letter-spacing: 0.8rem;
      font-size: 1.5rem !important;
      background-color: rgb(79, 79, 242);
      padding: 9px;
      border-radius: 10px;
      text-align: center;
    }

    &__line {
      height: 2px;
      background-color: yellow;
      width: 20%;
      margin: 20px 0;
    }

    &__name {
      color: yellow;
      text-transform: uppercase;
      font-weight: bold !important;
      margin-bottom: 10px !important;
      font-size: 1.2rem !important;
    }

    &__instrument {
      font-style: italic;
      font-size: 1.2rem !important;
    }

    &__details {
      font-style: italic;
      margin: 40px 0 !important;
      padding-left: 10px;
    }
    &__image {
      position: absolute;
      right: -30px;
      width: 60%;
      height: 105%;
      & img {
        object-fit: cover;
        height: 100%;
        min-width: 100%;
      }
    }

    &__text-container {
      position: relative;
      width: 30%;

      padding: 50px;
      z-index: 700;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -200px;
        width: 300px;
        height: 100%;
        background: #000;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(-10deg);
        -ms-transform: skew(-10deg);
        transform: skew(-10deg);
        z-index: -1;
      }
    }
  }
}

@media (max-width: 1000px) {
  .spotlight-outer-container {
    position: relative;
    padding: 20px;
    height: 100vh;
    max-width: 1400px;
    margin: auto;
  }
  .spotlight-container {
    overflow: hidden;
    position: relative;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    color: white;

    &__title {
      text-transform: uppercase;
      color: yellow !important;
      font-weight: bold !important;
      letter-spacing: 0.8rem;
      font-size: 1.3rem !important;
      background-color: rgb(79, 79, 242);
      padding: 9px;
      border-radius: 10px;
      text-align: center;
      width: 100%;
    }

    &__line {
      height: 2px;
      background-color: yellow;
      width: 20%;
      margin: 0;
      margin-bottom: 10px;
    }

    &__name {
      color: yellow;
      text-transform: uppercase;
      font-weight: bold !important;
      margin-bottom: 10px !important;
      font-size: 1rem !important;
    }

    &__instrument {
      font-style: italic;
      font-size: 1rem !important;
    }

    &__details {
      font-style: italic;

      margin: 30px 0 !important;
      padding-left: 10px;
    }
    &__image {
      position: absolute;
      right: 30px;
      width: 60%;
      // min-width: 300px;
      height: 105%;
      & img {
        object-fit: cover;
        height: 100%;
        min-width: 100%;
      }
    }

    &__text-container {
      position: relative;
      width: 30%;

      padding: 50px;
      z-index: 700;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -150px;
        width: 300px;
        height: 100%;
        background: #000;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(-10deg);
        -ms-transform: skew(-10deg);
        transform: skew(-10deg);
        z-index: -1;
      }
    }
  }
}

@media (max-width: 800px) {
  .spotlight-outer-container {
    position: relative;
    padding: 10px;
    height: fit-content;
    max-width: 1400px;
    margin: auto;
  }
  .spotlight-container {
    position: relative;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: fit-content;

    color: white;

    &__title {
      text-transform: uppercase;
      color: yellow !important;
      font-weight: bold !important;
      letter-spacing: 0.8rem;
      font-size: 1.2rem !important;
      background-color: rgb(79, 79, 242);
      padding: 9px;
      border-radius: 10px;
      text-align: center;
    }

    &__line {
      height: 2px;
      background-color: yellow;
      width: 20%;
      margin: 20px 0;
    }

    &__name {
      color: yellow;
      text-transform: uppercase;
      font-weight: bold !important;
      margin-bottom: 10px !important;
      font-size: 1.2rem !important;
    }

    &__instrument {
      font-style: italic;
      font-size: 1rem !important;
    }

    &__details {
      font-style: italic;
      margin: 30px 0 !important;
      padding-left: 10px;
    }
    &__image {
      position: relative;
      min-width: 100%;
      width: 100%;
      right: 0;

      & img {
        width: 100%;
        min-width: 100%;
      }
    }

    &__text-container {
      position: relative;
      width: 100%;
      padding: 50px;
      z-index: 700;

      &::after {
        display: none;
        content: "";
        position: absolute;
        top: 0;
        right: -200px;
        width: 100px;
        height: 100%;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(-10deg);
        -ms-transform: skew(-10deg);
        transform: skew(-10deg);
        z-index: -1;
      }
    }
  }
}
