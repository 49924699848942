@import "../../css-variables.scss";

.department-card {
  cursor: pointer;
  width: 380px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px;
  color: $secondary;
  position: relative;
  background-color: white;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__title {
    position: relative;
    z-index: 2;
  }

  &__image {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 0;
    opacity: 0.3;
    filter: saturate(0);
    transition: 400ms;

    &:hover {
      filter: saturate(1);
      opacity: 0.5;
    }
    img {
      width: 380px;
      height: 380px;
      object-fit: cover;
    }
  }
}
