@import "../../css-variables.scss";

.camp-card {
  padding: 26px;
  display: flex;
  background-color: $primary;
  color: $secondary;
  border-radius: 12px;
  margin-bottom: 24px;

  &__text {
    flex: 1;
  }

  &__image {
    margin: 16px;
    margin-right: 29px;
    width: 300px;
    & img {
      width: 100%;
    }
  }

  &__title {
    font-size: 1.8rem !important;
  }

  &__subtitle {
    font-size: 1.5rem !important ;
    // font-weight: bold !important;
  }

  &__dates {
    font-size: 1.5rem !important ;
    font-weight: bold !important;

    &--archived {
      font-size: 1rem !important;
    }
  }

  &__tag {
    font-size: 1.5rem !important ;
    margin-top: 20px !important;
    &--archived {
      font-size: 1.2rem !important;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    & button {
      width: 60%;
    }
  }
}

@media (max-width: 1000px) {
  .camp-card {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .camp-card {
    flex-direction: column;
    justify-content: center;
    &__image {
      width: 100%;
      margin: 0;
    }
    &__title {
      font-size: 2rem !important;
      text-align: center;
    }

    &__subtitle {
      text-align: center;
    }
    &__dates {
      font-size: 0.9rem !important;
    }
    &__tag {
      text-align: center;
    }
    &__button {
      flex-direction: column;
      align-items: center;
      text-align: center;
      & p {
        margin-bottom: 10px !important;
      }
    }
  }
}
