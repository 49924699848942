@import "../../css-variables.scss";
.content {
  min-height: 100vh;
  padding: 46px;
  max-width: 1400px;
  margin: auto;
}

.straight-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__image {
    width: 25%;
    margin: 6px;
    & img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}

.past-camps-divider {
  color: $primary;
  padding: 25px 0;
}

@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
